"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MzcApi = exports.HttpDataType = exports.HttpMethod = void 0;
var HttpMethod;
(function (HttpMethod) {
    HttpMethod["GET"] = "GET";
    HttpMethod["POST"] = "POST";
})(HttpMethod = exports.HttpMethod || (exports.HttpMethod = {}));
var HttpDataType;
(function (HttpDataType) {
    HttpDataType["TEXT"] = "text";
    HttpDataType["JSON"] = "json";
})(HttpDataType = exports.HttpDataType || (exports.HttpDataType = {}));
var MzcApi;
(function (MzcApi) {
    MzcApi["AuthStatus"] = "api/v1/my/auth/status";
    MzcApi["UserInfo"] = "api/v1/my/info";
    MzcApi["UserDevices"] = "api/v1/my/devices";
    MzcApi["UserDeviceSummary"] = "api/v1/my/devices/summary";
    MzcApi["UserReminderStatus"] = "api/v1/my/reminder_status";
    MzcApi["Logout"] = "oauth2/logout";
})(MzcApi = exports.MzcApi || (exports.MzcApi = {}));
