"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMenuItem = exports.getOverlay = exports.getEntry = void 0;
var document_1 = require("./document");
var enum_1 = require("./enum");
function getEntry(status, theme, text) {
    if (theme === void 0) { theme = enum_1.ZynetAccountTheme.light; }
    return (0, document_1.templateParser)("\n                <div class=\"zynet-account " + status + " " + theme + "\">\n                    <div class=\"zynet-account-sign-in\" zynet-translate=\"signIn\">" + text + "</div>\n                    <div class=\"zynet-account-name-circle\"></div>\n                </div>\n            ");
}
exports.getEntry = getEntry;
function getOverlay(theme) {
    if (theme === void 0) { theme = enum_1.ZynetAccountTheme.light; }
    return (0, document_1.templateParser)("\n                <div class=\"zynet-account-overlay " + theme + "\">\n                    <div class=\"zynet-account-popover\">\n                        <div class=\"zynet-account-user-info\">\n                            <div class=\"name-circle\">\n                                <div class=\"name\"></div>\n                            </div>\n                            <div class=\"info\">\n                                <div class=\"full-name\"></div>\n                                <div class=\"email\"></div>\n                            </div>\n                        </div>\n                        <ul class=\"zynet-account-menu-list\">\n                        </ul>\n                    </div>\n                </div>\n            ");
}
exports.getOverlay = getOverlay;
function getMenuItem(opt) {
    return (0, document_1.templateParser)("\n                <li class=\"zynet-account-menu-item " + opt.field + "\">\n                        <a href=\"" + opt.link + "\" rel=\"noopener\" target=\"" + opt.target + "\">\n                        <span class=\"menu-item-icon menu-item-icon--light\">" + opt.prefixIcon.light + "</span>\n                        <span class=\"menu-item-icon menu-item-icon--dark\">" + opt.prefixIcon.dark + "</span>\n                        <span class=\"menu-item-text\" zynet-translate=\"" + opt.field + "\">" + opt.text + "</span>\n                        <span class=\"menu-item-icon menu-item-icon--light menu-item-extra-icon\">" + opt.extraIcon.light + "</span>\n                        <span class=\"menu-item-icon menu-item-icon--dark menu-item-extra-icon\">" + opt.extraIcon.dark + "</span>\n                    </a>\n                </li>\n            ");
}
exports.getMenuItem = getMenuItem;
