"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var zynet_account_1 = require("./zynet-account");
require("./polyfill");
var instanceMap = new Map();
window['ZynetAccount'] = {
    init: function (selector, mzcOptions, options) {
        // check the selector have been init
        var target = document.querySelector(selector);
        if (target == null) {
            return;
        }
        if (target.classList.contains('activate') && instanceMap.get(target)) {
            return instanceMap.get(target);
        }
        var instance = new zynet_account_1.ZynetAccount(selector, mzcOptions, options);
        target.classList.add('activate');
        instanceMap.set(target, instance);
        return instance;
    },
    close: function () {
        // IE not support .values, use forEach instead
        instanceMap.forEach(function (instance) {
            instance.close();
        });
    },
};
