"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZynetAccountTheme = exports.ZynetAccountEvent = void 0;
var ZynetAccountEvent;
(function (ZynetAccountEvent) {
    ZynetAccountEvent["Loaded"] = "loaded";
    ZynetAccountEvent["signIn"] = "signIn";
})(ZynetAccountEvent = exports.ZynetAccountEvent || (exports.ZynetAccountEvent = {}));
var ZynetAccountTheme;
(function (ZynetAccountTheme) {
    ZynetAccountTheme["light"] = "light";
    ZynetAccountTheme["dark"] = "dark";
})(ZynetAccountTheme = exports.ZynetAccountTheme || (exports.ZynetAccountTheme = {}));
