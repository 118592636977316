"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.retrieve = exports.cleanUp = exports.setToken = exports.getToken = void 0;
var CookieTokenStorage = /** @class */ (function () {
    function CookieTokenStorage() {
        this.key = 'zyxel_oauth_token';
        this.cookiePattern = /(?:(?:^|.*;\s*)zyxel_oauth_token\s*=\s*([^;]*).*$)|^.*$/;
    }
    Object.defineProperty(CookieTokenStorage.prototype, "token", {
        get: function () {
            var token = document.cookie.replace(this.cookiePattern, '$1');
            return token.length ? token : null;
        },
        enumerable: false,
        configurable: true
    });
    CookieTokenStorage.prototype.setToken = function (token) {
        if (!token) {
            return;
        }
        document.cookie = this.key + "=" + token + ";secure";
    };
    CookieTokenStorage.prototype.clearToken = function () {
        document.cookie = this.key + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    };
    return CookieTokenStorage;
}());
var LocalTokenStorage = /** @class */ (function () {
    function LocalTokenStorage() {
        this.key = 'zyxel_oauth_token';
        this.storage = null;
        this.storage = window.localStorage;
        this.storage.getItem('check-storage-available');
    }
    Object.defineProperty(LocalTokenStorage.prototype, "token", {
        get: function () {
            return this.storage.getItem(this.key);
        },
        enumerable: false,
        configurable: true
    });
    LocalTokenStorage.prototype.setToken = function (token) {
        if (!token) {
            return;
        }
        this.storage.setItem(this.key, token);
    };
    LocalTokenStorage.prototype.clearToken = function () {
        this.storage.removeItem(this.key);
    };
    return LocalTokenStorage;
}());
var storage = null;
function getAvailableStorage() {
    if (!storage) {
        try {
            storage = new LocalTokenStorage();
        }
        catch (e) {
            storage = new CookieTokenStorage();
        }
    }
    return storage;
}
var URL_HASH_PATTERN = /#access_token=(.*?)(&)/i;
function retrieveFromUrlHash() {
    var results = URL_HASH_PATTERN.exec(window.location.hash);
    return results && results.length ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : null;
}
function getToken() {
    return getAvailableStorage().token;
}
exports.getToken = getToken;
function setToken(token) {
    getAvailableStorage().setToken(token);
}
exports.setToken = setToken;
function cleanUp() {
    getAvailableStorage().clearToken();
}
exports.cleanUp = cleanUp;
function retrieve() {
    var token = retrieveFromUrlHash();
    if (token) {
        getAvailableStorage().setToken(token);
    }
    else {
        token = getAvailableStorage().token;
    }
    return token;
}
exports.retrieve = retrieve;
