"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MzcLanguage = exports.LOCALIZE = exports.ASSETS = exports.OPEN_NEW_ICON = exports.SIGN_OUT_ICON = exports.MGMT_ACCOUNT_ICON = exports.ACCOUNT_ICON = exports.PREFIX = void 0;
// class prefix
exports.PREFIX = 'zynet-account';
/***************************************
 *                Assets
 ***************************************/
exports.ACCOUNT_ICON = 'account.svg';
exports.MGMT_ACCOUNT_ICON = 'manage_account.svg';
exports.SIGN_OUT_ICON = 'sign_in.svg';
exports.OPEN_NEW_ICON = 'open_new.svg';
exports.ASSETS = {
    ACCOUNT_ICON: exports.ACCOUNT_ICON,
    MGMT_ACCOUNT_ICON: exports.MGMT_ACCOUNT_ICON,
    SIGN_OUT_ICON: exports.SIGN_OUT_ICON,
    OPEN_NEW_ICON: exports.OPEN_NEW_ICON,
};
/***************************************
 *             I18n assets
 ***************************************/
exports.LOCALIZE = {
    'en-us': {
        signIn: 'Sign in',
        mgmtAccount: 'Manage account',
        signOut: 'Sign out',
    },
    'zh-tw': {
        signIn: '登入',
        mgmtAccount: '帳戶管理',
        signOut: '登出',
    },
    'de-de': {
        signIn: 'Anmelden',
        mgmtAccount: 'Konto verwalten',
        signOut: 'Ausloggen',
    },
    'fr-fr': {
        signIn: 'Se connecter',
        mgmtAccount: 'Gérer son compte',
        signOut: 'Déconnexion',
    },
    'ja-jp': {
        signIn: 'サインイン',
        mgmtAccount: 'アカウントを管理する',
        signOut: 'サインアウト',
    },
    'ru-ru': {
        signIn: 'войти в систему',
        mgmtAccount: 'Управлять счетом',
        signOut: 'выход',
    },
    'tr-tr': {
        signIn: 'Oturum aç',
        mgmtAccount: 'Hesabı yönet',
        signOut: 'Oturumu Kapat',
    },
};
/***************************************************
 *   Zynet-account <-> MZC language mapping table
 ***************************************************/
exports.MzcLanguage = {
    'en-us': 'en',
    it: 'it',
    'de-de': 'de',
    'fr-fr': 'fr',
    'tr-tr': 'tr',
    'zh-tw': 'zh-TW',
    pt: 'pt',
    'ru-ru': 'ru',
    th: 'th',
    'zh-CN': 'zh-CN',
};
