"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MzcSessionManager = exports.MzcSessionError = void 0;
var MzcUserToken = __importStar(require("./user-token"));
var definition_1 = require("./definition");
var MzcSessionError;
(function (MzcSessionError) {
    MzcSessionError["UNAUTHORIZED"] = "Unauthorized.";
})(MzcSessionError = exports.MzcSessionError || (exports.MzcSessionError = {}));
var MzcSessionManager = /** @class */ (function () {
    function MzcSessionManager(client) {
        this.client = client;
        this._authorized = false;
    }
    MzcSessionManager.prototype.clearToken = function () {
        MzcUserToken.cleanUp();
        this._authorized = false;
    };
    Object.defineProperty(MzcSessionManager.prototype, "token", {
        get: function () {
            var token = MzcUserToken.getToken();
            if (!token) {
                throw MzcSessionError.UNAUTHORIZED;
            }
            return token;
        },
        enumerable: false,
        configurable: true
    });
    MzcSessionManager.prototype.login = function () {
        if (this._authorized) {
            return;
        }
        this.client.toLogin();
    };
    MzcSessionManager.prototype.authorize = function () {
        return __awaiter(this, void 0, void 0, function () {
            var token, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._authorized) {
                            return [2 /*return*/];
                        }
                        token = MzcUserToken.retrieve();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getMyInfo(token)];
                    case 2:
                        _a.sent();
                        this._authorized = true;
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.clearToken();
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MzcSessionManager.prototype.checkAuthToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var token, data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._authorized) {
                            return [2 /*return*/, this._authorized];
                        }
                        token = MzcUserToken.retrieve();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.client.request(this.token, {
                                uri: definition_1.MzcApi.AuthStatus,
                                method: definition_1.HttpMethod.GET,
                                body: { token: token },
                                responseType: definition_1.HttpDataType.JSON,
                                timeout: 1500,
                            })];
                    case 2:
                        data = _a.sent();
                        if (data.result === true) {
                            this._authorized = true;
                        }
                        else {
                            this.clearToken();
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.warn('Error while getting authorization status, maybe unauthorized?');
                        console.warn(error_2.status + ": " + error_2.statusText);
                        this.clearToken();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, this._authorized];
                }
            });
        });
    };
    MzcSessionManager.prototype.getMyInfo = function (token) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.request(token || this.token, {
                            uri: definition_1.MzcApi.UserInfo,
                            params: { trigger: 'my_info', tragger_name: 'my_info' },
                            responseType: definition_1.HttpDataType.JSON,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MzcSessionManager.prototype.getMyDevices = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.request(this.token, {
                            uri: definition_1.MzcApi.UserDevices,
                            params: { trigger: 'my_devices', trigger_name: 'my_devices' },
                            responseType: definition_1.HttpDataType.JSON,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MzcSessionManager.prototype.getMyDevicesSummary = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.request(this.token, {
                            uri: definition_1.MzcApi.UserDeviceSummary,
                            params: { trigger: 'my_summary', trigger_name: 'my_summary' },
                            responseType: definition_1.HttpDataType.JSON,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MzcSessionManager.prototype.getMyReminderStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.request(this.token, {
                            uri: definition_1.MzcApi.UserReminderStatus,
                            params: { trigger: 'my_reminder_status', trigger_name: 'my_reminder_status' },
                            responseType: definition_1.HttpDataType.JSON,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MzcSessionManager.prototype.setAuthToken = function (token) {
        MzcUserToken.setToken(token);
    };
    MzcSessionManager.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var token;
            return __generator(this, function (_a) {
                token = this.token;
                this.clearToken();
                this.client.toLogout(token);
                return [2 /*return*/];
            });
        });
    };
    return MzcSessionManager;
}());
exports.MzcSessionManager = MzcSessionManager;
