"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.bind = exports.appendChild = exports.createElement = exports.getScreenHeight = exports.getScreenWidth = exports.select = exports.templateParser = void 0;
function templateParser(template) {
    return new DOMParser().parseFromString(template, 'text/html').body.children[0];
}
exports.templateParser = templateParser;
function select(selector) {
    return document.querySelector(selector);
}
exports.select = select;
function getScreenWidth() {
    return (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth ||
        document.body.offsetWidth);
}
exports.getScreenWidth = getScreenWidth;
function getScreenHeight() {
    return (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight ||
        document.body.offsetHeight);
}
exports.getScreenHeight = getScreenHeight;
function createElement(tag, attrs) {
    var e_1, _a;
    var dom = document.createElement(tag);
    if (attrs) {
        try {
            for (var _b = __values(Object.entries(attrs)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), field = _d[0], value = _d[1];
                dom.setAttribute(field, value);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    return dom;
}
exports.createElement = createElement;
function appendChild(target, child) {
    var targetDOM = typeof target === 'string' ? document.querySelector(target) : target;
    if (targetDOM) {
        targetDOM.appendChild(child);
    }
}
exports.appendChild = appendChild;
function bind(target, eventName, selector, callback) {
    var _target = selector ? target.querySelector(selector) : target;
    _target.addEventListener(eventName, callback);
}
exports.bind = bind;
