"use strict";
// TODO: extract this file to nfelib.core
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStorageArea = exports.RETAIN_STORAGE_KEYS = exports.StorageKey = exports.LegacyStorageKey = void 0;
var LegacyStorageKey;
(function (LegacyStorageKey) {
    // These storage keys only used on GUI 1.x
    LegacyStorageKey["StorageProfile"] = "storage-profile";
})(LegacyStorageKey = exports.LegacyStorageKey || (exports.LegacyStorageKey = {}));
var StorageKey;
(function (StorageKey) {
    StorageKey["SessionContinuity"] = "nebula://session/continuity";
})(StorageKey = exports.StorageKey || (exports.StorageKey = {}));
exports.RETAIN_STORAGE_KEYS = [
    // page continuity
    StorageKey.SessionContinuity,
];
var BrowserSessionStorage = /** @class */ (function () {
    function BrowserSessionStorage(prefix) {
        this.storage = window.sessionStorage;
        this.prefixPattern = prefix ? prefix + "://" : null;
    }
    BrowserSessionStorage.prototype.evalStorageKey = function (key) {
        return this.prefixPattern == null || key.startsWith(this.prefixPattern) ? key : "" + this.prefixPattern + key;
    };
    BrowserSessionStorage.prototype.hasPrefix = function (key) {
        return this.prefixPattern == null || key.startsWith(this.prefixPattern);
    };
    BrowserSessionStorage.prototype.getItem = function (key, defaults) {
        var ret = this.storage.getItem(this.evalStorageKey(key));
        if (ret) {
            try {
                return JSON.parse(ret);
            }
            catch (err) {
                // no error
            }
        }
        if (ret == null && defaults !== null) {
            return defaults;
        }
        return ret;
    };
    BrowserSessionStorage.prototype.setItem = function (key, value) {
        var ok = false;
        try {
            this.storage.setItem(this.evalStorageKey(key), JSON.stringify(value));
            ok = true;
        }
        catch (err) {
            // no error
        }
        return ok ? this.getItem(key) : null;
    };
    BrowserSessionStorage.prototype.removeItem = function (key) {
        var ret = this.getItem(key);
        try {
            this.storage.removeItem(this.evalStorageKey(key));
        }
        catch (err) {
            // no error
        }
        return ret;
    };
    BrowserSessionStorage.prototype.keys = function () {
        var _this = this;
        var keys = [];
        try {
            keys = Object.keys(this.storage).filter(function (k) { return _this.hasPrefix(k); });
        }
        catch (err) {
            // no error
        }
        return keys;
    };
    BrowserSessionStorage.prototype.clear = function () {
        var _this = this;
        var retain_keys = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            retain_keys[_i] = arguments[_i];
        }
        var ignoreKeys = retain_keys.map(function (k) { return _this.evalStorageKey(k); });
        try {
            this.keys()
                .filter(function (k) { return !ignoreKeys.includes(k); })
                .map(function (k) {
                console.info('delete key', k);
                _this.removeItem(k);
            });
        }
        catch (err) {
            // no error
        }
    };
    return BrowserSessionStorage;
}());
var MemoryStorage = /** @class */ (function () {
    function MemoryStorage() {
        this.storage = {};
    }
    MemoryStorage.prototype.getItem = function (key, defaults) {
        var ret = this.storage[key];
        if (ret) {
            try {
                return JSON.parse(ret);
            }
            catch (err) {
                // no error
            }
        }
        if (ret == null && defaults !== null) {
            return defaults;
        }
        return ret;
    };
    MemoryStorage.prototype.setItem = function (key, value) {
        this.storage[key] = JSON.stringify(value);
        return this.storage[key] || null;
    };
    MemoryStorage.prototype.removeItem = function (key) {
        var ret = this.getItem(key);
        try {
            delete this.storage[key]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
        }
        catch (err) {
            // no error
        }
        return ret;
    };
    MemoryStorage.prototype.keys = function () {
        return Object.keys(this.storage);
    };
    MemoryStorage.prototype.clear = function () {
        this.storage = {};
    };
    return MemoryStorage;
}());
function createStorageArea(prefix) {
    try {
        return new BrowserSessionStorage(prefix);
    }
    catch (err) {
        return new MemoryStorage();
    }
}
exports.createStorageArea = createStorageArea;
